/* General Styles */
/* General Styles */
.App {
  text-align: center;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-image: url('./background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.App-header {
  background-color: #283c44;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-size: 18px;
  color: white;
}

.navbar-logo {
  height: 100px; /* Adjust the height for a more reasonable size */
  width: auto;  /* Maintain aspect ratio */
}

.App-link {
  color: #61dafb;
  text-decoration: none;
}

.App-link:hover {
  text-decoration: underline;
}

/*style links*/
  a:link { 
    text-decoration: none; 
  } 
  a:visited { 
    text-decoration: none; 
  } 
  a:hover { 
    text-decoration: none; 
  } 
  a:active { 
    text-decoration: none; 
  }

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .navbar-logo {
    height: 60px; /* Make the logo smaller on mobile screens */
  }
}

.camp-list{
  display: grid;
  justify-content: center;
  padding-left: 2pc;
  padding-right: 2pc;
}

input.searchText{
  margin-top: 0.5pc;
  margin-bottom: 1.5pc;
  margin-right: 1.0pc;
}

.camp-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2pc;
  justify-content: center;
  max-width: 1500px;
  
}

.camp-card {
  display: flex;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 260px;
  height: 200px;
  justify-content: center;
  align-items: end;
}

.camp-info{
  width: 250px;
  border-radius: 8px;
  background-color: white;
  align-items: center;
  align-content: end;
  padding-bottom: 3px;
  margin-bottom: 4px;
}

.camp-title {
  font-size: 1.1em;
  margin-top: 3px;
  margin-bottom: 0px;
  color: #283c44;
}

.camp-cord {
  font-size: 0.67em;
  color: #666;
  margin: 0px;
  padding: 0px;
}

.pagination {
  margin-top: 20px;
  color: #ffffff;
  font-weight: bold;
}

.pagination button {
  padding: 10px 15px;
  margin: 0 5px;
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 10px;
  font-size: 1.2em;
}
.loading-container {
  text-align: center;
  margin-top: 20px;
}

.loading-bar {
  width: 100%;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
  height: 20px;
  margin-top: 10px;
}

.loading-progress {
  height: 100%;
  background-color: #4caf50;
  text-align: center;
  color: white;
  line-height: 20px;
  transition: width 0.3s;
}
.dropdown-checkbox {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.dropdown-button {
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
}

.clear-filters {
  margin-bottom: 10px;
}

.dropdown-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 350px;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 4px;
  border-radius: 4px;
}


.dropdown-item {
  display: flex;
  padding: 5px;
}

.dropdown-item input {
  margin-right: 8px;
  
}

